const ProgressBar = ({ title, completed }) => {
  const containerStyles = {
    height: 32,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
  };

  const fillerStyles = {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    width: `${completed}%`,
    backgroundColor: "rgb(99, 102, 241)",
    borderRadius: "inherit",
  };

  const labelStyles = {
    padding: "6px 10px",
    color: "white",
    fontWeight: "bold",
    fontSize: "14px",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{title}</span>
        <span style={labelStyles}>{`${completed}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
