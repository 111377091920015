const Section = ({ title, children }) => (
  <div className="flex flex-col items-center gap-10">
    <div className="flex flex-col gap-2 items-center">
      <h2 className="text-blue-950 text-3xl font-extrabold text-center">
        {title}
      </h2>
      <Divider />
    </div>

    {children}
  </div>
);

const Divider = () => <div className="h-1 w-14 border-indigo-500 border-b-2" />;

export default Section;
