const ShadowRoundedBox = ({
  image,
  title,
  subtitle,
  text,
  alt,
  badge,
  textAlign = "text-justify",
}) => {
  return (
    <div className="bg-white rounded-3xl p-6 shadow-2xl flex flex-col gap-2 items-center max-w-96 relative">
      <img
        src={image}
        alt={alt}
        className="rounded-full w-28 h-28 md:w-40 md:h-40 object-cover shadow-lg"
      />
      <div className="flex flex-col gap-0 text-center">
        <span className="text-xl font-bold font">{title}</span>
        <span className="font-light">{subtitle}</span>
      </div>
      <span className={`${textAlign}`}>{text}</span>
      {!!badge && (
        <div className="absolute top-0 md:-top-8 right-0 md:-right-8 w-20 h-20 bg-white rounded-3xl md:rounded-full border-4 border-indigo-500 flex flex-col items-center justify-center shadow-2xl">
          <img
            src={badge}
            className=" w-14 h-14 object-contain"
            alt={"Badge"}
          />
        </div>
      )}
    </div>
  );
};

export default ShadowRoundedBox;
