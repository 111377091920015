import assets from "../constants/assets";

const Technologies = () => (
  <div className="flex flex-row justify-center items-center gap-4 mb-4">
    <img
      src={assets.react_logo}
      alt="react"
      className="w-16 h-16 object-contain"
    />
    <img
      src={assets.flutter_logo}
      alt="flutter"
      className="w-24 h-24 object-contain"
    />
    <img
      src={assets.swift_logo}
      alt="swift"
      className="w-24 h-24 object-contain"
    />
  </div>
);

export default Technologies;
