const Footer = () => {
  const getCurrentYear = () => {
    let today = new Date();
    return today.getFullYear();
  };

  return (
    <div className="bg-indigo-500 h-40 mt-8 flex flex-col justify-center items-center">
      <span className="text-white">
        Všechna práva vyhrazena &copy; {getCurrentYear()} - Pavel Hřebíček
      </span>
    </div>
  );
};

export default Footer;
