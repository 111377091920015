import assets from "../constants/assets";
import { EnvelopeIcon } from "@heroicons/react/24/solid";

const Header = () => {
  const onEmailBtnPress = (e) => {
    window.location.href = "mailto:pavel.hrebicek@gmail.com";
    e.preventDefault();
  };

  return (
    <div className="flex flex-col md:flex-row md:justify-center items-center py-8 gap-7">
      <div className="flex flex-col items-center md:items-start gap-2">
        <h1 className="text-blue-950 text-4xl md:text-6xl font-bold font-sans text-center">
          Mobilní vývojář.
        </h1>
        <h1 className="text-blue-950 text-5xl font-bold font-sans text-center">
          Pavel Hřebíček
        </h1>
        <h3 className="font-extralight text-blue-950 text-center">
          Pomáhám realizovat Vaše podnikání v digitálním světě.
        </h3>
        <button
          onClick={onEmailBtnPress}
          className="bg-indigo-500 px-5 py-2 rounded-full my-5 hover:bg-indigo-800"
        >
          <div className="flex flex-row gap-2 items-center">
            <span className="text-white">Napište mi</span>
            <EnvelopeIcon className="size-5 text-white" />
          </div>
        </button>
        <div className="flex flex-row gap-5">
          <a
            href="https://www.linkedin.com/in/pavel-hřebíček-b7b02984"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={assets.linked_in}
              className="w-8 h-8"
              alt="Pavel Hřebíček LinkedIn"
            />
          </a>
          <a
            href="https://github.com/xhrebi04"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={assets.github}
              className="w-8 h-8"
              alt="Pavel Hřebíček GitHub"
            />
          </a>
        </div>
      </div>
      <img
        src={assets.me_profile}
        alt="Pavel Hřebíček"
        className="rounded-full w-64 h-64 md:w-96 md:h-96 object-cover shadow-lg"
      />
    </div>
  );
};

export default Header;
