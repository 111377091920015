const assets = {
  // Icons
  consultation: require("../assets/icons/consultation.png"),
  flutter_logo: require("../assets/icons/flutter_logo.png"),
  mobile: require("../assets/icons/mobile.png"),
  react_logo: require("../assets/icons/react_logo.png"),
  swift_logo: require("../assets/icons/swift_logo.png"),
  linked_in: require("../assets/icons/linkedin.png"),
  github: require("../assets/icons/github.png"),
  vycistimeto: require("../assets/icons/vycistimeto.png"),

  // Images
  me_profile: require("../assets/images/me_profile.jpg"),
  mandik: require("../assets/images/mandik.jpg"),
  chundelova: require("../assets/images/chundelova.jpeg"),
  fialova: require("../assets/images/fialova.jpeg"),
  danova: require("../assets/images/danova.jpeg"),
};

export default assets;
